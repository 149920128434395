import React from 'react'
import { Link } from 'gatsby'
import styles from './Footer.module.css'

export const Footer = ({ version }) => (
  <div className={styles.root}>
    <div className={styles.content}>
      <div className={styles.links}>
        <div className={styles.section}>
          <h6>Comparisons</h6>
          <div className={styles.link}>
            <Link to={'/comparisons/uservoice-vs-suggested'}>
              UserVoice vs Suggested
            </Link>
          </div>
          <div className={styles.link}>
            <Link to={'/comparisons/getsatisfaction-vs-suggested'}>
              Get Satisfaction vs Suggested
            </Link>
          </div>
          <div className={styles.link}>
            <Link to={'/comparisons/trello-vs-suggested'}>
              Trello vs Suggested
            </Link>
          </div>
          <div className={styles.link}>
            <Link to={'/comparisons/headway-vs-suggested'}>
              Headway vs Suggested
            </Link>
          </div>
        </div>
        <div className={styles.section}>
          <h6>Contact us</h6>
          <div className={styles.link}>
            <i className="far fa-fw fa-envelope" />{' '}
            <a href="mailto:hello@suggested.co">hello@suggested.co</a>
          </div>
          <div className={styles.link}>
            <i className="fab fa-fw fa-twitter" />{' '}
            <a href="https://twitter.com/suggestedco">@suggestedco</a>
          </div>
          <div className={styles.link}>
            <i className="far fa-fw fa-user-headset" />{' '}
            <Link to={'/support'}>Support</Link>
          </div>
          <div className={styles.link}>
            <i className="far fa-fw fa-book-spells" />{' '}
            <Link to={'/docs'}>Documentation</Link>
          </div>
        </div>
        <div className={styles.section}>
          <h6>About</h6>
          <div className={styles.link}>
            <i className="fa fa-fw fa-bolt" />{' '}
            <a href="https://feedback.suggested.co">Our product dashboard</a>
          </div>
        </div>
        <div className={styles.section}>
          <h6>Legal Stuff</h6>
          <div className={styles.link}>
            <Link to={'/terms'}>Terms</Link>
          </div>
          <div className={styles.link}>
            <Link to={'/privacy'}>Privacy</Link>
          </div>
          <div className={styles.link}>
            <Link to={'/security'}>Security</Link>
          </div>
        </div>
      </div>
      <div className={styles.copy}>
        &copy; {new Date().getFullYear()} Made with{' '}
        <i className="fas fa-fw fa-coffee" /> &amp;{' '}
        <i className="fas fa-fw fa-heart" /> in London by{' '}
        <a href="https://twitter.com/sanjeevan84">@sanjeevan84</a>
      </div>
      <div className={styles.version}>
        Version: <span>{version}</span>
      </div>
    </div>
  </div>
)

export default Footer
